// Background color utility mixin
@mixin bg-color-variant($name: ".bg-primary", $color: $primary-color) {
  #{$name} {
    background: $color;

    @if (lightness($color) < 60) {
      color: $light-color;
    }
  }
}

// Text color utility mixin
@mixin text-color-variant($name: ".text-primary", $color: $primary-color) {
  #{$name} {
    color: $color;
  }

  a#{$name} {
    &:focus,
    &:hover {
      color: darken($color, 5%);
    }
    &:visited {
      color: lighten($color, 5%);
    }
  }
}
